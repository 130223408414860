import React from 'react';
import { Formik, Field } from 'formik';
import classnames from 'classnames';
import {
  Col, Card,
} from 'antd';
import { Fields } from 'components';
import {
  Button, Titles, Row,
} from 'components/elements';
import styles from './filters.module.css';

const { BlueCardTitle } = Titles;

const Filters = ({
  actions, filters, title, query, defaultValues, headerButton,
}) => (
  <Col>
    <Card>
      <Row>
        <Col xs={24} sm={12} className={styles.searchTitle}>
          <BlueCardTitle>
            {`Search for ${title}`}
          </BlueCardTitle>
        </Col>
        <Col xs={24} sm={12} className={classnames(styles.rightAlign, styles.headerButton)}>
          {headerButton}
        </Col>
      </Row>
      <Row>
        <Formik
          enableReinitialization
          initialValues={query}
          onSubmit={(values, formActions) => {
            actions.updateFilters(values);
            actions.onPageSizeChange(values.limit);
            formActions.setSubmitting(false);
          }}
          render={({
            handleSubmit, isSubmitting, resetForm, values, setFieldValue,
          }) => (
            <form className={styles.filterForm} colon={false} onSubmit={handleSubmit}>
              <Row>
                <Col xs={24}>
                  <Row gutter={15} spacing={10}>
                    {filters.includes('name') && (
                      <Col xs={24} sm={12} lg={6}>
                        <Field
                          width="90%"
                          label="Name"
                          name="filters.name"
                          placeholder="Name"
                          component={Fields.Input}
                        />
                      </Col>
                    )}

                    {filters.includes('id') && (
                      <Col xs={24} sm={12} lg={6}>
                        <Field
                          width="90%"
                          label="ID"
                          name="filters.id"
                          placeholder="ID"
                          component={Fields.Input}
                        />
                      </Col>
                    )}

                    {filters.includes('status') && (
                    <Col xs={24} sm={12} lg={6}>
                      <Field
                        width="90%"
                        label="Status"
                        name="filters.decline"
                        options={[{ name: 'All', value: '' }, { name: 'Open', value: 'false' }, { name: 'Declined', value: 'true' }]}
                        component={Fields.Select}
                        placeholder="Open"
                      />
                    </Col>
                    )}
                  </Row>
                </Col>
              </Row>

              <Row spacing={15} gutter={15}>
                <Col xs={24} lg={8} xl={6} className={styles.filterButton}>
                  <Button
                    width="100%"
                    htmlType="submit"
                    disabled={isSubmitting}
                    color="blue"
                  >
                    Filter
                  </Button>
                </Col>
                {/* <Col xs={24} lg={8} xl={6}>
                  <Button
                    width="100%"
                    disabled={isSubmitting}
                    onClick={() => ()}
                  >
                    {`See all ${title}`}
                  </Button>
                </Col> */}
              </Row>
            </form>
          )}
        />
      </Row>
    </Card>
  </Col>
);

export default Filters;
