import { useEffect, useRef } from 'react';
import isEqual from 'lodash/isEqual';
import usePrevious from './usePrevious';

const useDeepEffect = (callback, inputs) => {
  const previousInputs = usePrevious(inputs);
  const inputsAreEqual = isEqual(inputs, previousInputs);
  const cleanupRef = useRef();
  useEffect(() => {
    if (!inputsAreEqual) {
      cleanupRef.current = callback();
    }
    return cleanupRef.current;
  }, [inputsAreEqual]);
};

export default useDeepEffect;
