import React from 'react';
import { Menu, Icon } from 'antd';
import { Link } from 'react-router-dom';

const { Item } = Menu;

const NavItem = ({
  perform, to, type, children, link, ...props
}) => (
  <Item {...props}>
    {to
      ? (
        <Link to={to}>
          {type && <Icon type={type} />}
          <span className="nav-text">{children}</span>
        </Link>
      ) : (
        <a className="nav-text" href={link} target="blank">
          {children}
        </a>
      )}
  </Item>
);

export default NavItem;
