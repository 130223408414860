import React from 'react';
import { Row as RowAnt } from 'antd'
import styled from 'styled-components'

const Row = ({ spacing, children, ...props }) => (
  <RowAnt
    style={{ marginBottom: `${spacing || 0}px` }}
    {...props}
  >
    {children}
  </RowAnt>
);


export default Row
