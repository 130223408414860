import { useEffect, useState, useRef } from 'react';
import isEqual from 'lodash/isEqual';
import axios from 'common/utils/axios';

const { get } = axios;

const useRefetchableResource = ({ path, data }) => {
  const [resource, setResource] = useState([]);
  const [fetching, setFetching] = useState(true);
  const [error, setError] = useState(null);

  const getResponse = async () => {
    setFetching(true);
    try {
      const res = await get(path, { params: data });
      setResource(res.data);
    } catch (e) {
      setError(e);
    } finally {
      setFetching(false);
    }
  };


  useEffect(() => {
    getResponse();
  }, []);


  useEffect(() => {
    const hasQueryChanged = isEqual(prevQuery.current, data);
    const hasPathChange = isEqual(prevPath.current, path);
    if (!fetching) {
      if (!hasQueryChanged || !hasPathChange) {
        getResponse();
      }
    }
  });

  const prevQuery = useRef();
  const prevPath = useRef();
  useEffect(() => {
    prevQuery.current = data;
    prevPath.current = path;
  });


  return {
    resource,
    fetching,
    error,
    fetch: getResponse,
  };
};

export default useRefetchableResource;
