import React, { useState, useRef } from 'react';
import { utils, services } from 'common';
import sampleCSV from 'assets/SampleCSV.csv';
import sampleCSVPhoto from 'assets/sampleCSVPhoto.png';
import { Card, message, Col } from 'antd';
import { Elements, Fragments } from 'components';
import styles from './upload.module.css';


const { mapAxiosError } = utils;
const { Button, Row } = Elements;
const { Drawers: { ResDrawer } } = Fragments;
const { MerchantSubmissions } = services.api;

const Upload = () => {
  const [errors, setErrors] = useState(false);
  const [file, setFile] = useState();
  const fileInput = useRef();

  const uploadFile = (event) => {
    event.preventDefault();
    const file = fileInput.current.files[0];
    setFile(file);
  };

  const postFile = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('filename', file);

    try {
      await MerchantSubmissions.upload(formData);
      message.success('File Succesfully Uploaded');
      fileInput.current = undefined;
      setFile();
      setErrors(false);
    } catch (error) {
      message.error(mapAxiosError(error));
      setErrors(true);
    }
  };

  return (
    <Card bordered={false}>
      <div className={styles.uploadContainer}>
        <form onSubmit={postFile}>
          <Row spacing={50}>
            <input type="file" name="themeFile" accept=".csv" ref={fileInput} onChange={uploadFile} />
          </Row>
          <Button disabled={!file} size="small" htmlType="submit">Upload</Button>
        </form>

        <ResDrawer
          title="Upload Help"
          placement="right"
          width="50vw"
          button={(
            <div className={styles.helpText}>
            How to format your file
              {errors && (
              <div>
                <span className={styles.errorText}>Your file could not be uploaded. Please ensure that it is formatted correctly. </span>
              </div>
              )}
            </div>
            )}
        >
          {drawerProps => (
            <div>
              Your file should be in CSV format and include all columns exactly as they are shown below. Column names are case sensitive and CSVs are limited to 500 merchants. A sample CSV can also be downloaded.
              <Row>
                <Col xs={24}>
                  <img className={styles.sampleImage} src={sampleCSVPhoto} alt="CSV Sample" />
                </Col>
              </Row>
              <div className={styles.helpText}><a href={sampleCSV} download="HelpMainStreet_SampleCSV">Download Sample CSV</a></div>
            </div>
          )}
        </ResDrawer>
      </div>
    </Card>
  );
};

export default Upload;
