import InteractiveTable from './InteractiveTable';
import Table from './Table';
import SelectionTable from './SelectionTable';
import PersistentTable from './PersistentTable';

export {
  InteractiveTable,
  Table,
  SelectionTable,
  PersistentTable,
};
export default {
  InteractiveTable,
  Table,
  SelectionTable,
  PersistentTable,
};
