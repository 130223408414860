import axios from 'axios';
import Qs from 'qs';
import { baseURI } from 'config';

const axiosInstance = axios.create({
  baseURL: baseURI,
});

axiosInstance.defaults.paramsSerializer = params => Qs.stringify(params, { arrayFormat: 'brackets' });

const storageKey = process.env.REACT_APP_LOCAL_STORAGE_KEY;
/**
 * on each request we need to send auth headers
 */
axiosInstance.interceptors.request.use((config) => {
  const { accessToken } = JSON.parse(localStorage.getItem(storageKey)) || {};
  Object.assign(config.headers.common, {
    authorization: `${accessToken}`,
  });

  return config;
}, error => Promise.reject(error));

/**
 * on each response we need to grab the auth headers
 * and persist it to a local storage
 */
axiosInstance.interceptors.response.use((response) => {
  const accessToken = response.data.token;
  if (accessToken) {
    localStorage.setItem(
      storageKey,
      JSON.stringify({ accessToken }),
    );
  }
  return response;
}, error => Promise.reject(error));

const {
  get, put, post, delete: destroy,
} = axiosInstance;

export {
  get, put, post, destroy,
};
export default {
  get, put, post, destroy,
};
