import PageSpinner from './PageSpinner';
import Button from './Button';
import T from './Text';
import Titles from './Titles';
import { Row } from './Table';
import { Tag } from './Tag';

export default {
  PageSpinner, Button, T, Titles, Row, Tag,
};
export {
  PageSpinner, Button, T, Titles, Row, Tag,
};
