import Toggles from './Toggle';
import Tables from './Table';
import { ConfirmationButton } from './ConfirmationButton';
import { SubmissionStatus } from './SubmissionStatus';
import { EntityToggle } from './EntityToggle';
import Drawers from './Drawers';

export {
  Toggles, Tables, ConfirmationButton, SubmissionStatus, EntityToggle, Drawers,
};
export default {
  Toggles, Tables, ConfirmationButton, SubmissionStatus, EntityToggle, Drawers,
};
