import React from 'react';
import classnames from 'classnames';
import { SaveToggle } from '../Toggle';
import styles from './entityToggle.module.css';

const EntityToggle = props => (
  <SaveToggle
    className={classnames(styles.entityToggle, 'largeToggle')}
    checkedChildren="Enabled Everywhere"
    unCheckedChildren="Disabled Everywhere"
    field="isActive"
    {...props}
  />
);
export default EntityToggle;
