import * as React from 'react';
import { Card, message } from 'antd';
import { ReactComponent as Logo } from 'assets/logo.svg';
import { ReactComponent as SmallLogo } from 'assets/login_logo.svg';
import { services, utils } from 'common';
import { Elements, Providers } from 'components';
import styles from './login.module.css';

const { Button, T } = Elements;
const { Admins } = services.api;
const { mapAxiosError } = utils;

const Login = () => {
  const { setUser } = React.useContext(Providers.User.UserContext);
  const [pwValue, setPwValue] = React.useState('');
  const [emailValue, setEmailValue] = React.useState('');

  const login = async (e) => {
    e.preventDefault();
    try {
      const res = await Admins.signIn({ email: emailValue, password: pwValue });
      setUser(res.data.admin);
    } catch (err) {
      message.error(mapAxiosError(err));
    }
  };

  const handlePwChange = (e) => {
    setPwValue(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmailValue(e.target.value);
  };

  return (
    <div className={styles['page-wrapper']}>
      <div className={styles.cardWrapper}>
        <Card
          bordered={false}
          className={styles['login-container']}
          headStyle={{
            borderBottom: 'none',
          }}
          title={(
            <div className={styles.headerContainer}>
              <div className={styles.logoContainer}>
                <SmallLogo />
              </div>
              <div className={styles.lunchboxLogoContainer}>
                <Logo />
              </div>
            </div>
          )}
        >
          <form onSubmit={login}>
            <label htmlFor="email">
              <T label>
                Email
              </T>
            </label>
            <div className={styles.emailWrapper}>
              <input className={styles.loginInput} id="email" name="email" type="email" value={emailValue} onChange={handleEmailChange} />
            </div>
            <label htmlFor="password">
              <T label>
                Password
              </T>
            </label>
            <div className={styles.passwordWrapper}>
              <input className={styles.loginInput} id="password" name="password" type="password" value={pwValue} onChange={handlePwChange} />
            </div>
            <div className={styles.loginButtonWrapper}>
              <Button size="medium" color="blue" htmlType="submit" value="Submit">Login</Button>
            </div>
          </form>
        </Card>
      </div>
    </div>
  );
};


export default Login;
