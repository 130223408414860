import React from 'react';
import { Formik, Field } from 'formik';
import { useParams } from 'react-router-dom';
import {
  Col, Card, message,
} from 'antd';
import { services, utils, schemas } from 'common';
import { useRefetchableResource } from 'hooks';
import { Elements, Fields, Fragments } from 'components';
import styles from './merchants.module.css';

const { Row, Button, T } = Elements;
const { Input, Toggle } = Fields;
const { EntityToggle } = Fragments;
const { Merchants } = services.api;
const { mapAxiosError } = utils;
const { MerchantSchema } = schemas;

const defaultQuery = {
  filters: {},
  skip: 0,
  limit: 20,
};

const defaultValues = {
  isActive: false,
  isVerified: false,
  site: '',
  address: '',
  giftCard: '',
  name: '',
  id: '',
};

const Merchant = (props) => {
  const { id } = useParams();
  const { resource, fetching, error } = useRefetchableResource({ path: `/restaurant-list/${id}` });
  const [errors, setErrors] = React.useState(false);
  const [initialValues, setInitialValues] = React.useState(defaultValues);

  const validateForm = vals => MerchantSchema.validate(vals)
    .then((values) => {
      setErrors(false);
      return values;
    })
    .catch((errs) => {
      setErrors(true);
    });

  const updateMerchant = async (values) => {
    validateForm(values)
      .then(async (vals) => {
        if (vals) {
          try {
            await Merchants.update(id, values);
            message.success('Merchant Successfully Update');
          } catch (err) {
            message.error(mapAxiosError(err));
          }
        }
      });
  };

  React.useEffect(() => {
    if (!fetching) {
      const {
        isActive, isVerified, site, address, giftCard, orderLink, name, id, donate,
      } = resource.results;
      setInitialValues({
        isActive,
        isVerified,
        site,
        address,
        giftCard,
        orderLink,
        name,
        id,
        donate,
      });
    }
  }, [fetching]);

  return (
    <Card>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values, formActions) => {
          updateMerchant(values);
        }}
        render={({
          handleSubmit, isSubmitting, resetForm, values, setFieldValue,
        }) => (
          <form
            onSubmit={(e) => {
              e.stopPropagation();
              handleSubmit(e);
            }}
          >

            <Row gutter={15} spacing={15}>
              <Col xs={24} md={12}>
                <Field
                  label="Merchant Name"
                  name="name"
                  placeholder="Merchant Name"
                  component={Input}
                  disabled={fetching}
                />
              </Col>
              <Col xs={24} md={12} className={styles.isActiveToggleColumn}>
                <EntityToggle
                  checked={values.isActive}
                  field="isActive"
                  path={`/restaurant-temp/${id}`}
                  disabled={fetching}
                />
              </Col>
            </Row>

            <Row gutter={15} spacing={15}>
              <Col xs={24} md={12}>
                <Field
                  label="Website"
                  name="site"
                  placeholder="Website"
                  component={Input}
                  disabled={fetching}
                />
              </Col>
            </Row>

            <Row gutter={15} spacing={15}>
              <Col xs={24} md={12}>
                <Field
                  label="Gift Card Link"
                  name="giftCard"
                  placeholder="Gift Card Link"
                  component={Input}
                  disabled={fetching}
                />
              </Col>
            </Row>

            <Row gutter={15} spacing={15}>
              <Col xs={24} md={12}>
                <Field
                  label="Order Link"
                  name="orderLink"
                  placeholder="Order Link"
                  component={Input}
                  disabled={fetching}
                />
              </Col>
              <Col xs={24} md={12}>
                <Field
                  label="Donation Link"
                  name="donate"
                  placeholder="Donation Link"
                  component={Input}
                  disabled={fetching}
                />
              </Col>
            </Row>

            <Row gutter={15} spacing={15}>
              <Col xs={24} md={12}>
                <Field
                  label="Address"
                  name="address"
                  placeholder="Address"
                  component={Input}
                  disabled={fetching}
                />
              </Col>
            </Row>

            <Button size="small" color="blue" htmlType="submit">Save</Button>
            {errors && (
            <div>
              <span className={styles.errorText}>All fields must contain valid input to submit</span>
            </div>
            )}
          </form>
        )}
      />
    </Card>
  );
};

export default Merchant;
