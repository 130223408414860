import React, { useContext } from 'react';
import {
  Route, Switch, Redirect,
} from 'react-router-dom';
import { Layout } from 'antd';
import { Hocs, Providers } from 'components';
import Pages from '../pages';
import Header from './Header';
import SideNav from './SideNav';

const { Content } = Layout;
const { withLayout } = Hocs;
const {
  Login, Merchants, Merchant, Upload, MerchantSubmissions, MerchantSubmission, CreateAdmin,
} = Pages;

const layout1 = {
  xs: 24,
};

const RouteWithProps = ({
  path, exact, component: Component, ...props
}) => (
  <Route
    path={path}
    exact={exact}
    render={routeProps => (
      <Component {...routeProps} {...props} />
    )}
  />
);

// const Home = withLayout(Pages.Home.Home, layout1);

const Routes = ({ location }) => {
  const { user, logout } = useContext(Providers.User.UserContext);

  if (user) {
    return (
      <Layout style={{ minHeight: '100vh' }}>
        {/* <SideNav onLogout={logout} location={location} /> */}
        <SideNav onLogout={logout} location={location} />
        <Layout>
          <Header />
          <Content>
            <Switch>
              <Route
                path="/upload"
                render={routeProps => (
                  <Upload
                    {...routeProps}
                  />
                )}
              />

              <Route
                path="/restaurants/:id"
                render={routeProps => (
                  <Merchant
                    {...routeProps}
                  />
                )}
              />

              <Route
                path="/restaurants"
                render={routeProps => (
                  <Merchants
                    {...routeProps}
                  />
                )}
              />

              <Route
                path="/restaurant-submissions/:id"
                render={routeProps => (
                  <MerchantSubmission
                    {...routeProps}
                  />
                )}
              />

              <Route
                path="/restaurant-submissions"
                render={routeProps => (
                  <MerchantSubmissions
                    {...routeProps}
                  />
                )}
              />

              <Route
                path="/admins/create"
                render={routeProps => (
                  <CreateAdmin
                    {...routeProps}
                  />
                )}
              />

              <Redirect from="*" to="/restaurants" />

            </Switch>
          </Content>
        </Layout>
      </Layout>
    );
  }

  return (
    <Switch>
      <Route path="/login" component={Login} />
      <Redirect from="*" to="/login" />
    </Switch>
  );
};

export default Routes;
