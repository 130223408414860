const {
  REACT_APP_ENV = 'development',
} = process.env;

const baseURI = (() => {
  switch (REACT_APP_ENV) {
    case 'production': return 'https://gifts.lunchbox.io';
    case 'development': return 'https://gifts.lunchbox.dev';
    default: return 'https://gifts.lunchbox.dev' || 'http://localhost:3000';
  }
})();

export { baseURI };
