import Drawer from './Drawer';
import ResDrawer from './ResDrawer';

export {
  Drawer,
  ResDrawer,
};
export default {
  Drawer,
  ResDrawer,
};
