import React, { useState, useEffect } from 'react';
import { Elements } from 'components';
import { services } from 'common';

const { Admins } = services.api;
const { PageSpinner } = Elements;

const UserContext = React.createContext();
const storageKey = process.env.REACT_APP_LOCAL_STORAGE_KEY;

const Provider = ({ children, ...props }) => {
  const [user, setUser] = useState();
  const [fetching, setFetching] = useState(true);

  const validate = async () => {
    try {
      const res = await Admins.validate();

      if (res.status === 200) {
        await setUser(res.data);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setFetching(false);
    }
  };

  const logout = () => {
    localStorage.removeItem(storageKey);
    setUser(undefined);
  };

  useEffect(() => {
    validate();
  }, []);

  const contextValues = {
    user,
    logout,
    setUser,
  };

  return fetching ? (
    <PageSpinner />
  ) : (
    <UserContext.Provider value={contextValues}>
      {typeof children === 'function' ? (
        React.Children.only(children(contextValues))
      ) : (
        children
      )}
    </UserContext.Provider>
  );
};

export default {
  Provider,
  UserContext,
};
