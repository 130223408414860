import qs from 'qs';
import cloneDeep from 'lodash/cloneDeep';
import useDeepEffect from './useDeepEffect';
import useReactRouter from './useReactRouter';


const cleanQuery = (queryValue, defaultQuery) => {
  Object.keys(queryValue).forEach((key) => {
    if (queryValue[key] === defaultQuery[key]) {
      delete queryValue[key];
    } else if (typeof queryValue[key] === 'object') {
      cleanQuery(queryValue[key], defaultQuery[key]);
    }
  });
};


const useQueryString = ({
  query, defaults,
}) => {
  const { history } = useReactRouter();
  useDeepEffect(() => {
    const queryClone = cloneDeep(query);
    cleanQuery(queryClone, defaults);
    history.replace({
      search: qs.stringify(queryClone),
    });
  }, [query]);
};

export default useQueryString;
