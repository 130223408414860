import { Login } from './Login';
import { Merchants, Merchant } from './Merchants';
import { MerchantSubmissions, MerchantSubmission } from './MerchantSubmissions';
import { Upload } from './Upload';
import { Create as CreateAdmin } from './Admins';

export default {
  Login, Merchants, Merchant, Upload, MerchantSubmissions, MerchantSubmission, CreateAdmin,
};
export {
  Login, Merchants, Merchant, Upload, MerchantSubmissions, MerchantSubmission, CreateAdmin,
};
