import React from 'react';
import { withRouter } from 'react-router-dom';
import Table from './Table';
import styles from './table.module.css';

const InteractiveTable = ({
  children, history, path, striped, pagination, ...props
}) => {
  const redirectLink = (id) => {
    history.push(`${path}${id}`);
  };
  return (
    <Table
      striped={striped}
      pagination={pagination}
      clickable
      onRow={record => ({
        onClick: () => {
          redirectLink(record._id || record.id);
        },
      })}
      {...props}
    >
      {children}
    </Table>
  );
};

InteractiveTable.defaultProps = {
  size: 'small',
  striped: true,
  pagination: false,
};

export default withRouter(InteractiveTable);
