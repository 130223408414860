import React from 'react';
import { useSessionStorage } from 'hooks';

const withSessionPersistence = Component => ({ persistKey, ...props }) => {
  const { value, actions } = useSessionStorage({ key: persistKey, initial: { page: 1 } });
  const pagination = persistKey ? {
    current: value.page,
    onChange: (page, pageSize) => {
      actions.change({ page });
    },
  } : undefined;
  return <Component {...props} pagination={pagination} />;
};

export default withSessionPersistence;
