import React from 'react';
import { Button } from 'components/elements';
import { ConfirmationButton } from 'components/fragments/ConfirmationButton';
import styles from './availabilityButtons.module.css';

const EntityAvailabilityButtons = ({
  selectedIds, postAvailability, fetching,
}) => {
  const onMakeAvailable = () => {
    if (!fetching) {
      postAvailability(true);
    }
  };

  const onMakeUnavailable = () => {
    if (!fetching) {
      postAvailability(false);
    }
  };

  return (
    <div className={styles.buttonWrapper}>
      <ConfirmationButton
        placement="bottom"
        title="This will affect all selected locations with valid input. Are you sure?"
        okText="Yes"
        onConfirm={onMakeAvailable}
        visible={selectedIds[0]}
      >
        <Button disabled={!selectedIds[0] || fetching} color="blue" size="medium">
          Accept
        </Button>
      </ConfirmationButton>
      &nbsp;
      <ConfirmationButton
        placement="bottom"
        title="This will affect all selected locations with valid input. Are you sure?"
        okText="Yes"
        onConfirm={onMakeUnavailable}
        visible={selectedIds[0]}
      >
        <Button disabled={!selectedIds[0] || fetching} className={styles.unavailableButton} color="red" size="medium">
          Decline
        </Button>
      </ConfirmationButton>
    </div>
  );
};


export default EntityAvailabilityButtons;
