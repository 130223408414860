import * as Yup from 'yup';

const adminSchema = Yup.object({
  firstName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('First Name is required'),
  lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Last Name is required'),
});

const newAdminSchema = adminSchema.shape({
  email: Yup.string().trim().email('Invalid email').required('Email is required'),
  phone: Yup.string().matches(/^[0-9 ()+-]{10,}$/i, 'Phone number is not valid').required('Phone number is required'),
  password: Yup.string().min(8, 'Password is too short - should be 8 characters minimum.').matches(/[a-zA-Z]/, "Password can't be only numbers.").required('Password is required'),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords do not match')
    .required('Please re-enter password'),
});

export default {
  NewAdminSchema: newAdminSchema,
};
