import React from 'react';
import { Button as AntButton } from 'antd';
import classnames from 'classnames';
import styles from './button.module.css';

const colorClass = (color) => {
  switch (color) {
    case 'transparentRed': return styles.transparentRed;
    case 'transparent': return styles.transparent;
    case 'blue': return styles.blue;
    case 'white': return styles.white;
    case 'green': return styles.green;
    case 'red': return styles.red;
    default: return '';
  }
};

const sizeClass = (size) => {
  switch (size) {
    case 'extraSmall': return styles.extraSmall;
    case 'small': return styles.small;
    case 'medium': return styles.medium;
    case 'large': return styles.large;
    default: return '';
  }
};

const Button = ({
  children, color, className, size, marginLeft, borderless, width, height, ...props
}) => {
  const classes = classnames(
    colorClass(color),
    sizeClass(size),
    marginLeft && styles.marginLeft,
    borderless && styles.borderless,
    styles.button,
    className,
  );

  return (
    <AntButton
      {...props}
      type="button"
      className={classes}
      style={{ width, height }}
    >
      {children}
    </AntButton>
  );
};

Button.defaultProps = {
  color: 'transparent',
};

export default Button;
