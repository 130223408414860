import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import { useWindowSize } from 'hooks';
import { services } from 'common';
import EntityAvailabilityButtons from './EntityAvailabilityButtons';
import EntityActionsDropdown from './EntityActionsDropdown';

const { MerchantSubmissions } = services.api;

const EntityAvailability = ({ onSuccess, selectedIds, setSelectedIds, setErrors }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 576);
  const [fetching, setFetching] = useState(false);
  const { width } = useWindowSize();

  useEffect(() => {
    setIsMobile(width < 576);
  }, [width]);

  const postAvailability = async (available) => {
    setFetching(true);
    try {
      if (available) {
        const { data: { notAccepted } } = await MerchantSubmissions.accept({ accepted: selectedIds });
        const isErrors = notAccepted.length;
        setErrors(isErrors);

        onSuccess();
        message.success('Successfully Accepted');
      }
      if (!available) {
        await MerchantSubmissions.decline({ declined: selectedIds });
        setSelectedIds([]);

        onSuccess();
        message.success('Successfully Declined');
      }
    } catch (e) {
      message.error('An error occurred');
    } finally {
      setFetching(false);
    }
  };

  return (
    <div>
      {isMobile
        ? (
          <EntityActionsDropdown
            selectedIds={selectedIds}
            postAvailability={postAvailability}
            fetching={fetching}
          />
        )
        : (
          <EntityAvailabilityButtons
            selectedIds={selectedIds}
            postAvailability={postAvailability}
            fetching={fetching}
          />
        )
      }
    </div>
  );
};

export default EntityAvailability;
