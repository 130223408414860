import React, { useState } from 'react';
import { Layout } from 'antd';
import { ReactComponent as Logo } from 'assets/logo.svg';

const { Header: AntHeader } = Layout;

const Header = ({
  onActiveLocationChange, activeLocationID, locations, pathname,
}) => {

  return (
    <AntHeader>
      <Logo width="20%" />
    </AntHeader>
  );
};

export default Header;
