import React from 'react';
import { Drawer as AntDrawer } from 'antd';

const Drawer = ({
  className, children, placement, isOpen, trigger, drawerProps, title, onClose,
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(isOpen || false);
  const toggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const close = () => {
    onClose && onClose();
    setIsDrawerOpen(false);
  };

  React.useEffect(() => {
    setIsDrawerOpen(isOpen);
  }, [isOpen]);

  return (
    <>
      {trigger && React.cloneElement(trigger, { onClick: toggle })}
      <AntDrawer
        placement={placement}
        visible={isDrawerOpen}
        onClose={close}
        title={title}
        {...drawerProps}
      >
        {(drawerProps.destroyOnClose && !isDrawerOpen) ? null
          : typeof children === 'function' ? (
            React.Children.only(children({ close }))
          ) : (
            React.Children.only(React.cloneElement(children, { close }))
          )
        }
      </AntDrawer>
    </>
  );
};

export default Drawer;
