import { useReducer } from 'react';
import qs from 'qs';
import useRefetchableResource from './useRefetchableResource';
import useQueryString from './useQueryString';
import useReactRouter from './useReactRouter';

const initializeQuery = validFilters => (queryString) => {
  const params = qs.parse(queryString.slice(1));
  params.skip = params.skip ? parseInt(params.skip, 10) : validFilters.skip || 0;
  params.limit = params.limit ? parseInt(params.limit, 10) : validFilters.limit || 10;
  if (!params.filters) {
    params.filters = {};
  }
  Object.entries(validFilters.filters).forEach(([key, value]) => {
    if (!params.filters.hasOwnProperty(key)) {
      params[key] = value;
    }
  }, {});

  Object.keys(params.filters).forEach((key) => {
    if (!Object.keys(validFilters.filters).includes(key)) {
      delete params[key];
    }
  }, {});

  const { filters, ...rest } = params;
  return {
    filters,
    ...rest,
  };
};

const queryReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_FILTERS':
      return {
        ...state,
        ...action.payload.filters,
        skip: 0,
      };
    case 'LIMIT_CHANGE':
      return {
        ...state,
        limit: action.payload,
        skip: 0,
      };
    case 'SORT_CHANGE':
      if (state.sort && state.sort[action.payload]) {
        if (state.sort[action.payload] === 1) {
          return { ...state, sort: { [action.payload]: -1 } };
        }
        const { sort, ...withoutSort } = state;
        return withoutSort;
      }
      return { ...state, sort: { [action.payload]: 1 } };
    case 'SKIP_CHANGE':
      return { ...state, skip: action.payload };
    default:
      return state;
  }
};

const useSearchQuery = ({
  path, method, defaults, extraQuery = { filters: {} },
}) => {
  const { location } = useReactRouter();
  const queryInitializer = initializeQuery(defaults);

  const [query, dispatchQuery] = useReducer(queryReducer, queryInitializer(location.search));

  useQueryString({ query, defaults });

  const params = {
    ...query,
    filters: {
      ...query.filters,
      ...extraQuery.filters,
    },
  };

  const data = useRefetchableResource({ path, method, data: params });

  const updateFilters = (payload) => {
    dispatchQuery({
      type: 'UPDATE_FILTERS',
      payload,
    });
  };

  const onSortChange = (payload) => {
    dispatchQuery({
      type: 'SORT_CHANGE',
      payload,
    });
  };

  const onPageChange = (payload) => {
    dispatchQuery({
      type: 'SKIP_CHANGE',
      payload,
    });
  };

  const onPageSizeChange = (payload) => {
    dispatchQuery({
      type: 'LIMIT_CHANGE',
      payload,
    });
  };

  return {
    query,
    actions: {
      updateFilters,
      onPageChange,
      onPageSizeChange,
      onSortChange,
    },
    ...data,
  };
};

export default useSearchQuery;
