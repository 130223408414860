import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import {
  Layout, Menu, Icon,
} from 'antd';
import { useWindowSize } from 'hooks';
import NavItem from './NavItem';

const { Sider } = Layout;
const { Item } = Menu;

const Nav = ({ onLogout, location }) => {
  const [collapsed, setCollapsed] = useState(false);
  const { breakpoint } = useWindowSize();
  const ref = React.useRef();

  const handleClick = (e) => {
    const node = ReactDOM.findDOMNode(ref.current);
    if (!node.contains(e.target)) {
      setCollapsed(true);
    }
  };

  React.useEffect(() => {
    if (!collapsed && ['xs', 'sm'].includes(breakpoint.name)) {
      setCollapsed(true);
    }
  }, [location]);

  React.useEffect(() => {
    if (!collapsed && ['xs', 'sm'].includes(breakpoint.name)) {
      document.addEventListener('click', handleClick);
    } else {
      document.removeEventListener('click', handleClick);
    }
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [collapsed, breakpoint.name]);

  return (
    <Sider
      width={225}
      ref={ref}
      theme="light"
      collapsed={collapsed}
      onCollapse={collapse => setCollapsed(collapse)}
      collapsedWidth="0"
      breakpoint="md"
    >

      {/* <Logo /> */}

      <Menu mode="inline" selectedKeys={[location.pathname]}>

        <NavItem key="/restaurants" to="/restaurants">
          <span className="nav-text">Merchants</span>
        </NavItem>

        <NavItem key="/restaurant-submissions" to="/restaurant-submissions">
          <span className="nav-text">Merchant Submissions</span>
        </NavItem>

        <NavItem key="/upload" to="/upload">
          <span className="nav-text">Upload</span>
        </NavItem>

        {/* <NavItem key="/admins/create" to="/admins/create">
          <span className="nav-text">Create Admin</span>
        </NavItem> */}

        <Item key="/logout">
          <PseudoLink onClick={onLogout}>
            <span className="nav-text">Logout</span>
          </PseudoLink>
        </Item>
      </Menu>
    </Sider>
  );
};

const PseudoLink = props => (
  <a href="#" {...props} />
);

export default Nav;
