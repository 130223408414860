import React from 'react';
import {
  BrowserRouter as Router, Route,
} from 'react-router-dom';
import Routes from 'routes';
import { Providers } from './components';
import './App.css';

function App() {
  return (
    <div className="App">
      <Providers.User.Provider>
        <Router>
          <Route render={(routeProps => <Routes {...routeProps} />)} />
        </Router>
      </Providers.User.Provider>
    </div>
  );
}

export default App;
