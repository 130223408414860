import React from 'react';
import { Card, Col, Table } from 'antd';
import { Templates, Fragments, Elements } from 'components';
import { useSearchQuery } from 'hooks';

const { Filters } = Templates;
const { Tables: { InteractiveTable }, Toggles: { SaveToggle } } = Fragments;
const { Titles: { CardTitle } } = Elements;

const defaultQuery = {
  filters: {},
  skip: 0,
  limit: 20,
};

const Merchants = () => {
  const {
    query, resource, fetching, actions,
  } = useSearchQuery({ defaults: defaultQuery, path: '/restaurant-list/' });

  const onPageChange = ({ current }) => {
    actions.onPageChange((current - 1) * query.limit);
  };

  const paginationObj = (skip, limit, total) => ({
    current: (skip + limit) / limit,
    pageSize: limit,
    total,
    onChange: page => onPageChange(page),
  });

  let total = 0;
  let results;
  if (resource) {
    if (resource.total) {
      ({ total } = resource);
    }
    ({ results } = resource);
  }

  return (
    <>
      <Filters
        actions={actions}
        title="Merchants"
        filters={[
          'name',
        ]}
        query={query}
        defaultValues={defaultQuery}
      />

      <Card>
        <Col xs={12}>
          <CardTitle>
            Merchants
          </CardTitle>
        </Col>
        <Col>
          <InteractiveTable
            dataSource={results}
            pagination={paginationObj(query.skip, query.limit, total)}
            onChange={onPageChange}
            loading={fetching}
            // history={history}
            path="/restaurants/"
          >
            <Table.Column
              title="Name"
              dataIndex="name"
            />
            <Table.Column
              title="Gift Card Link"
              dataIndex="giftCard"
              width="700px"
              render={txt => <a target="_blank" href={txt}>{txt}</a>}
            />
            <Table.Column
              title="Is Active"
              dataIndex="isActive"
              render={(txt, record) => (
                <SaveToggle
                  checkedChildren="Active"
                  uncheckedChildren="Inactive"
                  field="isActive"
                  checked={record.isActive}
                  path={`/restaurant-list/${record.id}`}
                />
              )}
            />
          </InteractiveTable>
        </Col>
      </Card>
    </>
  );
};

export default Merchants;
