import React from 'react';
import { Card, Col, Table } from 'antd';
import { Templates, Fragments, Elements } from 'components';
import { useSearchQuery } from 'hooks';
import styles from './merchantSubmissions.module.css';

const { Filters } = Templates;
const { Tables: { SelectionTable }, SubmissionStatus } = Fragments;
const { Titles: { CardTitle }, Row } = Elements;
const { EntityAvailability } = Templates;

const defaultQuery = {
  filters: {
    decline: false,
  },
  skip: 0,
  limit: 20,
};

const MerchantSubmissions = () => {
  const {
    query, resource, fetching, actions, fetch: fetchMerchantSubmissions,
  } = useSearchQuery({ defaults: defaultQuery, path: '/restaurant-temp/' });
  const [selectedSubmissions, setSelectedSubmissions] = React.useState([]);
  const [errors, setErrors] = React.useState(false);

  const onPageChange = ({ current }) => {
    actions.onPageChange((current - 1) * query.limit);
  };

  const paginationObj = (skip, limit, total) => ({
    current: (skip + limit) / limit,
    pageSize: limit,
    total,
    onChange: page => onPageChange(page),
  });

  const setSelectedMerchantSubmissions = (submissions) => {
    setSelectedSubmissions(submissions);
  };

  let total = 0;
  let results;
  if (resource) {
    if (resource.total) {
      ({ total } = resource);
    }
    ({ results } = resource);
  }

  return (
    <>
      <Filters
        actions={actions}
        title="Merchant Submissions"
        filters={[
          'name', 'status',
        ]}
        query={query}
        defaultValues={defaultQuery}
      />

      <Card>
        <Row>
          <Col xs={6}>
            <CardTitle>
            Merchant Submissions
            </CardTitle>
          </Col>
          <Col xs={18} className={styles.entityAvailability}>
            <EntityAvailability
              setSelectedIds={setSelectedSubmissions}
              selectedIds={selectedSubmissions}
              onSuccess={fetchMerchantSubmissions}
              setErrors={setErrors}
            />
            {errors && (
            <div>
              <span className={styles.errorText}>Some of the selected merchants had invalid input and could not be confirmed</span>
            </div>
            )}
          </Col>
        </Row>
        <Col>
          <SelectionTable
            dataSource={results}
            pagination={paginationObj(query.skip, query.limit, total)}
            onChange={onPageChange}
            loading={fetching}
            setSelected={setSelectedMerchantSubmissions}
            selected={selectedSubmissions}
            path="/restaurant-submissions/"
          >
            <Table.Column
              title="Name"
              dataIndex="name"
            />
            <Table.Column
              title="Status"
              dataIndex="declined"
              render={(txt, record) => <SubmissionStatus declined={record.decline} />}
            />
            <Table.Column
              title="Gift Card Link"
              dataIndex="giftCard"
              width="700px"
              render={txt => <a target="_blank" href={txt}>{txt}</a>}
            />
          </SelectionTable>
        </Col>
      </Card>
    </>
  );
};

export default MerchantSubmissions;
