import { useState, useEffect } from 'react';
import isEqual from 'lodash/isEqual';
import usePrevious from './usePrevious';

const initialItems = (key, initial) => {
  const items = JSON.parse(window.sessionStorage.getItem(key));
  return items || initial;
};

const useSessionStore = ({ key, initial }) => {
  const [value, setValue] = useState(() => initialItems(key, initial));

  const onChange = (nextValue) => {
    setValue(nextValue);
  };
  const onClear = () => {
    setValue({});
  };

  useEffect(() => {
    if (!isEqual(value, prevValue)) {
      window.sessionStorage.setItem(key, JSON.stringify(value));
    }
  });

  const prevValue = usePrevious(value);

  return {
    value,
    actions: {
      change: onChange,
      clear: onClear,
    },
  };
};

export default useSessionStore;
