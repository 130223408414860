import * as React from 'react';
import { Tag } from 'components/elements';
import styles from './submissionStatus.module.css';

const tagColor = declined => (declined ? styles.declined : styles.open);

const tagText = declined => (declined ? 'Declined' : 'Open');

const SubmissionStatus = ({ declined }) => (
  <Tag className={tagColor(declined)}>
    {tagText(declined)}
  </Tag>
);


export default SubmissionStatus;
