import axios from './axios';
import mapAxiosError from './mapAxiosError';

const determineBreakpoint = (width) => {
  const breakpoints = [
    { width: 576, name: 'sm' },
    { width: 768, name: 'md' },
    { width: 992, name: 'lg' },
    { width: 1200, name: 'xl' },
    { width: 1600, name: 'xxl' },
  ];
  return breakpoints.reduce((accu, i) => (width < i.width ? accu : i), { width: 0, name: 'xs' });
};

export default { determineBreakpoint, axios, mapAxiosError };
export { determineBreakpoint, axios, mapAxiosError };