import * as React from 'react';
import { Switch, Form } from 'antd';
import { T } from '../elements';

const Toggle = ({
  field: { name, value },
  form: { setFieldValue },
  label,
}) => (
  <Form.Item label={<T label inline>{label}</T>}>
    <Switch
      checked={value}
      onChange={checked => setFieldValue(name, checked)}
    />
  </Form.Item>
);

export default Toggle;
