import React from 'react';
import { Row, Col } from 'antd';

const withLayout = (Component, layout) => props => (
  <Row>
    <Col {...layout}>
      <Component {...props} />
    </Col>
  </Row>
);

export default withLayout;
