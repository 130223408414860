import React, { useState } from 'react';
import { Popconfirm } from 'antd';


const ConfirmationButton = ({
  visible, onCancel, onConfirm, isConditional, ...props
}) => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const confirm = () => {
    setIsPopupVisible(false);
    onConfirm();
  };

  const cancel = () => {
    setIsPopupVisible(false);
    return onCancel && onCancel();
  };

  const handleVisibleChange = (visible) => {
    if (!visible) {
      setIsPopupVisible(true);
      return;
    }

    if (isConditional) {
      setIsPopupVisible(visible);
    } else {
      confirm();
    }
  };

  return (
    <Popconfirm
      {...props}
      visible={visible && isPopupVisible}
      onVisibleChange={handleVisibleChange}
      onConfirm={confirm}
      onCancel={cancel}
    />
  );
};

ConfirmationButton.defaultProps = {
  visible: true,
  isConditional: true,
};

export default ConfirmationButton;
