import React from 'react';
import { Table as AntTable } from 'antd';
import classnames from 'classnames';
import styles from './table.module.css';

const { Column } = AntTable;

const Table = ({
  children, columns, striped, clickable, rowClassName: additionalRowClassNames, className, ...props
}) => {
  const rowClassName = (text, index) => {
    const additionalClassNames = typeof additionalRowClassNames === 'function' ? additionalRowClassNames(text, index, styles) : additionalRowClassNames;

    return classnames(additionalClassNames, styles.row);
  };

  const tableClassName = classnames(
    striped ? styles.striped : null,
    clickable ? styles.click : null,
    styles.table,
    className,
  );

  const mappedChildren = () => {
    if (columns) {
      return columns.reduce((accu, column, index) => {
        const { perform, ...rest } = column;

        accu.push(<Column key={index} {...column} />);

        return accu;
      }, []);
    }
    return React.Children.toArray(children).reduce((accu, child, index) => {
      const { perform, yes, ...rest } = child.props;
      
      accu.push(child);

      return accu;
    }, []);
  };

  return (
    <AntTable
      rowKey={i => i._id || i.id}
      {...props}
      className={tableClassName}
      rowClassName={rowClassName}
      childrenColumnName={['randall']}
    >
      {mappedChildren()}
    </AntTable>
  );
};

Table.defaultProps = {
  size: 'small',
};

export default Table;
