import * as Yup from 'yup';

const merchant = Yup.object({
  address: Yup.string().required('Address is required'),
  name: Yup.string().required('Name is required'),
  giftCard: Yup.string().required('Gift Card is required'),
  site: Yup.string(),
  orderLink: Yup.string().optional(),
  donate: Yup.string().optional(),
});

export default merchant;
