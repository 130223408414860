import React from 'react';
import { Formik, Field } from 'formik';
import { useParams, useHistory } from 'react-router-dom';
import {
  Col, Card, message,
} from 'antd';
import { services, utils, schemas } from 'common';
import { useRefetchableResource, useSearchQuery } from 'hooks';
import { Elements, Fields, Fragments } from 'components';
import styles from './merchantSubmissions.module.css';

const { Row, Button } = Elements;
const { Input, Toggle } = Fields;
const { MerchantSubmissions } = services.api;
const { mapAxiosError } = utils;
const { ConfirmationButton, EntityToggle } = Fragments;
const { MerchantSchema } = schemas;

const defaultValues = {
  isActive: false,
  decline: false,
  site: '',
  address: '',
  giftCard: '',
  name: '',
  id: '',
  donate: '',
};

const MerchantSubmission = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const {
    resource, fetching, error, fetch: fetchMerchantTemp,
  } = useRefetchableResource({ path: `/restaurant-temp/${id}` });
  const [errors, setErrors] = React.useState(false);
  const [initialValues, setInitialValues] = React.useState(defaultValues);
  const { decline, isActive, ...formValues } = initialValues;

  const validateForm = vals => MerchantSchema.validate(vals)
    .then((values) => {
      setErrors(false);
      return values;
    })
    .catch((errs) => {
      setErrors(true);
    });

  const updateMerchant = async (values) => {
    validateForm(values)
      .then(async (vals) => {
        if (vals) {
          try {
            await MerchantSubmissions.update(id, vals);
            message.success('Merchant Submission Successfully Updated');
          } catch (err) {
            message.error(mapAxiosError(err));
          }
        }
      });
  };

  const handleAccept = async (values) => {
    validateForm(values)
      .then(async (vals) => {
        if (vals) {
          try {
            await MerchantSubmissions.update(id, vals);
            await MerchantSubmissions.accept({ accepted: [id] });
            history.push('/restaurant-submissions');
            message.success('Merchant Submission Successfully Accepted');
          } catch (err) {
            message.error(mapAxiosError(err));
          }
        }
      });
  };

  const handleDecline = async () => {
    try {
      await MerchantSubmissions.decline({ declined: [id] });
      history.push('/restaurant-submissions');
      message.success('Merchant Submission Successfully Declined');
    } catch (err) {
      message.error(mapAxiosError(err));
    }
  };

  React.useEffect(() => {
    if (!fetching) {
      const {
        isActive, site, address, giftCard, orderLink, name, decline, donate,
      } = resource.results;

      setInitialValues({
        isActive,
        site,
        address,
        giftCard,
        orderLink,
        name,
        decline,
        donate,
      });
    }
  }, [fetching]);

  return (
    <Card>
      <Formik
        enableReinitialize
        initialValues={formValues}
        onSubmit={(values) => {
          updateMerchant(values);
        }}
        render={({
          handleSubmit, values,
        }) => (
          <form
            onSubmit={(e) => {
              e.stopPropagation();
              handleSubmit(e);
            }}
          >

            <Row gutter={15} spacing={15}>
              <Col xs={24} md={12}>
                <Field
                  label="Merchant Name"
                  name="name"
                  placeholder="Merchant Name"
                  component={Input}
                  disabled={fetching}
                />
              </Col>
            </Row>

            <Row gutter={15} spacing={15}>
              <Col xs={24} md={12}>
                <Field
                  label="Website"
                  name="site"
                  placeholder="Website"
                  component={Input}
                  disabled={fetching}
                />
              </Col>
            </Row>

            <Row gutter={15} spacing={15}>
              <Col xs={24} md={12}>
                <Field
                  label="Gift Card Link"
                  name="giftCard"
                  placeholder="Gift Card Link"
                  component={Input}
                  disabled={fetching}
                />
              </Col>
            </Row>

            <Row gutter={15} spacing={15}>
              <Col xs={24} md={12}>
                <Field
                  label="Order Link"
                  name="orderLink"
                  placeholder="Order Link"
                  component={Input}
                  disabled={fetching}
                />
              </Col>
              <Col xs={24} md={12}>
                <Field
                  label="Donation Link"
                  name="donate"
                  placeholder="Donation Link"
                  component={Input}
                  disabled={fetching}
                />
              </Col>
            </Row>

            <Row gutter={15} spacing={15}>
              <Col xs={24} md={12}>
                <Field
                  label="Address"
                  name="address"
                  placeholder="Address"
                  component={Input}
                  disabled={fetching}
                />
              </Col>
            </Row>

            <Row gutter={15} spacing={15}>
              <Col xs={24} md={12}>
                <Button disabled={fetching} size="small" color="blue" htmlType="submit">Save</Button>
              </Col>
              <Col xs={24} md={12}>
                <ConfirmationButton
                  placement="bottom"
                  title="This will permanently accept this merchant submission. Are you sure?"
                  okText="Yes"
                  onConfirm={() => handleAccept(values)}
                >
                  <Button disabled={fetching} size="small" color="blue">Accept</Button>
                </ConfirmationButton>
                <ConfirmationButton
                  placement="bottom"
                  title="This will permanently decline this merchant submission. Are you sure?"
                  okText="Yes"
                  onConfirm={handleDecline}
                >
                  <Button disabled={fetching || decline} className={styles.declineButton} color="red" size="small">
                    {decline ? 'Declined' : 'Decline'}
                  </Button>
                </ConfirmationButton>
              </Col>
              {errors && (
                <div>
                  <span className={styles.errorText}>All fields must contain valid input to submit</span>
                </div>
              )}
            </Row>
          </form>
        )}
      />
    </Card>
  );
};

export default MerchantSubmission;
