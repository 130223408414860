import { SectionTitle, CardTitle, BlueCardTitle } from './Title';

export default {
  SectionTitle,
  CardTitle,
  BlueCardTitle,
};
export {
  SectionTitle,
  CardTitle,
  BlueCardTitle,
};
