import React from 'react';
import { Col, Card, message } from 'antd';
import { Formik, Field } from 'formik';
import { services, schemas, utils } from 'common';
import { Elements, Fields } from 'components';

const { Row, Button } = Elements;
const { Admins } = services.api;
const { Input } = Fields;
const { mapAxiosError } = utils;

const Create = () => {
  const handleCreateAdmin = async (values) => {
    const data = {
      ...values,
      passwordConfirm: undefined,
      email: values.email.trim(),
      role: 0,
    };

    try {
      console.log(data);
      await Admins.create(data);
      message.success('Admin Successfully Created');
    } catch (err) {
      message.error(mapAxiosError(err));
    }
  };

  return (
    <Formik
      enableReinitialization
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
      }}
      validationSchema={schemas.Admin.NewAdminSchema}
      onSubmit={(values, formActions) => {
        handleCreateAdmin(values);
        formActions.resetForm();
      }}
      render={({
        handleSubmit, isSubmitting, resetForm, values, setFieldValue, errors,
      }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            {console.log(errors)}
            <Row gutter={15} spacing={15}>
              <Col xs={24} md={12}>
                <Field
                  label="First Name"
                  name="firstName"
                  placeholder="First Name"
                  component={Input}
                />
              </Col>
              <Col xs={24} md={12}>
                <Field
                  label="Last Name"
                  name="lastName"
                  placeholder="Last Name"
                  component={Input}
                />
              </Col>
            </Row>

            <Row gutter={15} spacing={15}>
              <Col xs={24} md={12}>
                <Field
                  label="Password"
                  name="password"
                  placeholder="Password"
                  type="password"
                  component={Input}
                />
              </Col>
              <Col xs={24} md={12}>
                <Field
                  label="Re-Enter Password"
                  name="passwordConfirm"
                  placeholder="Re-Enter Password"
                  type="password"
                  component={Input}
                />
              </Col>
            </Row>

            <Row gutter={15} spacing={15}>
              <Col xs={24} md={12}>
                <Field
                  label="Email"
                  name="email"
                  placeholder="Email"
                  component={Input}
                />
              </Col>
              <Col xs={24} md={12}>
                <Field
                  label="Phone"
                  name="phone"
                  placeholder="Phone"
                  component={Input}
                />
              </Col>
            </Row>

            <Row spacing={40}>
              <Col xs={12}>
                <Button
                  htmlType="submit"
                  size="small"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  color="blue"
                >
                Create
                </Button>
              </Col>
            </Row>
          </Card>
        </form>
      )}
    />
  );
};

export default Create;
