import * as React from 'react';
import { getIn } from 'formik';
import PropTypes from 'prop-types';
import { Select, Form } from 'antd';
import classnames from 'classnames';
import { T } from 'components/elements';
import styles from './field.module.css';

const { Option } = Select;

const SelectField = ({
  field: { onChange, ...field },
  form: {
    touched, errors, setFieldValue, setFieldTouched,
  }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  width,
  selectedName,
  className,
  options,
  placeholder,
  ...props
}) => {
  const errorMsg = getIn(errors, field.name);

  return (
    <Form.Item
      label={<span inline className={styles.selectLabel}>{label}</span>}
      help={errorMsg}
      validateStatus={errorMsg ? 'error' : undefined}
    >
      <Select
        {...field}
        {...props}
        placeholder={placeholder}
        style={{ width }}
        className={classnames(styles.select, className)}
        optionLabelProp={selectedName || undefined}
        onChange={newValue => setFieldValue(field.name, newValue)}
        onBlur={newValue => setFieldTouched(field.name)}
      >
        {options.map((i, index) => <Option className={styles.selectItem} key={index} value={i.value}>{i.name}</Option>)}
      </Select>
    </Form.Item>
  );
};

SelectField.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]).isRequired,
    }),
  ).isRequired,
};

SelectField.defaultProps = {
  size: 'default',
  width: '90%',
  placeholder: '',
};

export default SelectField;
