import React, { useState } from 'react';
import {
  Dropdown, Menu,
} from 'antd';
import { Button } from 'components/elements';
import { ConfirmationButton } from 'components/fragments/ConfirmationButton';

const EntityAvailabilityDropdown = ({
  selectedIds, postAvailability, fetching,
}) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(true);

  const onTrigger = () => {
    if (isDropdownVisible) {
      setIsConfirmationVisible(false);
      setIsDropdownVisible(false);
    } else {
      setIsConfirmationVisible(true);
    }
  };

  const onConfirm = () => {
    setIsDropdownVisible(true);
  };

  const onMakeAvailable = () => {
    if (!fetching) {
      postAvailability(true);
    }
  };
  const onMakeUnavailable = () => {
    if (!fetching) {
      postAvailability(false);
    }
  };

  function handleMenuClick(e) {
    if (e.key === '1') {
      onMakeAvailable();
      setIsDropdownVisible(false);
    } else {
      onMakeUnavailable();
      setIsDropdownVisible(false);
    }
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1">
        Accept
      </Menu.Item>
      <Menu.Item key="2">
        Decline
      </Menu.Item>
    </Menu>
  );

  return (
    <ConfirmationButton
      placement="bottom"
      title="This will affect all selected locations with valid input. Are you sure?"
      okText="Yes"
      onConfirm={onConfirm}
      visible={isConfirmationVisible}
    >
      <Dropdown
        visible={isDropdownVisible}
        disabled={!selectedIds[0] || fetching}
        overlay={menu}
        trigger="click"
      >
        <Button size="small" onClick={onTrigger}>
        Actions
          {' '}
          v
        </Button>
      </Dropdown>
    </ConfirmationButton>
  );
};


export default EntityAvailabilityDropdown;
