import React, { useState, useEffect } from 'react';
import InteractiveTable from './InteractiveTable';
import styles from './table.module.css';

const LocationSelectionTable = ({
  height,
  setSelected,
  loading,
  selected,
  children,
  dataSource,
  ...props
}) => {
  const rowSelection = {
    selectedRowKeys: selected,
    onChange: (selectedRowKeys) => {
      setSelected(selectedRowKeys);
    },
    getCheckboxProps: record => ({
      disabled: record.decline,
    }),
  };

  return (
    <InteractiveTable
      rowSelection={rowSelection}
      loading={loading}
      pagination={false}
      scroll={{ y: height }}
      dataSource={dataSource}
      {...props}
    >
      {children}
    </InteractiveTable>
  );
};

LocationSelectionTable.defaultProps = {
  dataSource: [],
};

export default LocationSelectionTable;
