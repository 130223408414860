import React, { useState, useEffect } from 'react';
import { Titles } from 'components/elements';
import Drawer from './Drawer';
import { useWindowSize } from '../../../hooks';
import { utils } from '../../../common';
import styles from './drawers.module.css';

const ResDrawer = ({
  children, button, title, ...props
}) => {
  const { width } = useWindowSize();
  const [drawerWidth, setWidth] = useState();

  const newWidth = (breakpoint) => {
    switch (breakpoint) {
      case 'sm': setWidth('80vw'); break;
      case 'md': setWidth('50vw'); break;
      case 'lg':
      case 'xl':
      case 'xxl': setWidth('40vw'); break;
      default: setWidth('100vw');
    }
  };

  useEffect(() => {
    const { name: breakpoint } = utils.determineBreakpoint(width);
    newWidth(breakpoint);
  }, [width]);

  return (
    <Drawer
      {...props}
      drawerProps={{
        width: drawerWidth,
        destroyOnClose: true,
      }}
      trigger={button}
      title={(
        <Titles.SectionTitle className={styles.drawerTitle}>
          {title}
        </Titles.SectionTitle>
      )}
    >
      {children}
    </Drawer>
  );
};

export default ResDrawer;
