import React from 'react';
import classnames from 'classnames';
import { Typography } from 'antd';
import styles from './text.module.css';

const T = ({
  children, className, copyable, ellipsis, underline, inline, label, ...props
}) => {
  const classes = classnames(
    inline ? styles.inlineText : styles.text,
    label && styles.label,
    styles.text,
    className,
  );
  return (
    <div className={classes} {...props}>
      <Typography.Text copyable={copyable} ellipsis={ellipsis} underline={underline}>
        {children}
      </Typography.Text>
    </div>
  );
};

T.defaultProps = {
  className: null,
  copyable: false,
  ellipsis: false,
  underline: false,
};

export default T;
