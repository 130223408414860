import { withCustomClass } from '../../hocs';
import styles from './title.module.css';

const Title = withCustomClass('p', styles.title);
const SectionTitle = withCustomClass(Title, styles.sectionTitle);
const CardTitle = withCustomClass(Title, styles.cardTitle);
const BlueCardTitle = withCustomClass(Title, styles.blueCardTitle);

export {
  SectionTitle,
  CardTitle,
  BlueCardTitle,
};

export default {
  SectionTitle,
  CardTitle,
  BlueCardTitle,
};
