import { axios } from '../../utils';

const {
  get, put, post, destroy,
} = axios;

const Admins = {
  signIn: data => post('/admins/sign-in/', data),
  create: data => post('/admins', data),
  validate: data => get('/admins', data),
};

const Merchants = {
  update: (id, data) => put(`/restaurant-list/${id}`, data),
};

const MerchantSubmissions = {
  update: (id, data) => put(`/restaurant-temp/${id}`, data),
  accept: data => post('/restaurant-temp/accept', data),
  decline: data => put('/restaurant-temp/decline', data),
  upload: data => post('/restaurant-temp-upload', data),
};

export default {
  Admins, Merchants, MerchantSubmissions,
};
export {
  Admins, Merchants, MerchantSubmissions,
};
