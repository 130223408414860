import React from 'react';
import classnames from 'classnames';
import styles from './tag.module.css';

const Tag = ({ color, backgroundColor, children, className, style }) => {
  return (
    <div 
      className={classnames(styles.tag, className)} 
      style={{ backgroundColor: backgroundColor, color: color, borderColor: color, ...style }}
    >
        {children}
    </div>
  );
};

export default Tag;
