import * as React from 'react';
import { getIn } from 'formik';
import PropTypes from 'prop-types';
import { Input, InputNumber, Form } from 'antd';
import { T } from 'components/elements';
import classnames from 'classnames';
import styles from './field.module.css';

const { TextArea, Password } = Input;

const sizeClass = (size, type) => {
  if (type === 'number') {
    switch (size) {
      case 'small': return styles.numberSmall;
      default: return '';
    }
  } else {
    switch (size) {
      case 'small': return styles.textSmall;
      default: return '';
    }
  }
};

const borderClass = border => border && styles.border;

const InputField = ({
  field: { onChange, ...field },
  form: { touched, errors, setFieldValue },
  // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  type,
  size,
  border,
  error,
  width,
  formatter,
  parser,
  ...props
}) => {
  const errorMsg = getIn(errors, field.name);
  const classes = classnames(
    sizeClass(size),
    borderClass(border),
    styles.input,
  );

  let Comp = Input;
  switch (type) {
    case 'textarea': Comp = TextArea; break;
    case 'password': Comp = Password; break;
    case 'number': Comp = InputNumber; break;
    default: Comp = Input;
  }

  return (
    <Form.Item
      label={<T label inline>{label}</T>}
      help={errorMsg}
      validateStatus={errorMsg ? 'error' : undefined}
    >
      <Comp
        {...field}
        {...props}
        className={classes}
        formatter={formatter}
        parser={parser}
        size={size}
        style={{ width }}
        onChange={
          type === 'number'
            ? newValue => setFieldValue(field.name, newValue)
            : onChange
        }
      />
    </Form.Item>
  );
};

InputField.propTypes = {
  prefix: PropTypes.element,
  label: PropTypes.string,
};

InputField.defaultProps = {
  size: 'default',
};

export default InputField;
